import React from "react";
import TextBlock from "../components/general/text/TextBlock";
import { TCTextRSH } from "../assets/data/TextBlocks";

function TACPage() {
  return (
    <div id="T&Cs_Page">
      <TextBlock blocks={TCTextRSH}></TextBlock>
    </div>
  );
}

export default TACPage;

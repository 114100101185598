import React, { useState, useEffect } from "react";
import { currencyFormatter } from "../../../shared/utilities/Formatters";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
  ChartDataLabels
);

function BarChart(props) {
  const preReformBarColour = "rgb(0, 138, 137)";
  const postReformBarColour = "rgb(174, 207, 230)";
  const titleFontSize = 17;
  const normalFontSize = 14;
  const fontColor = "rgb(0,0,0)";
  const moneyTerms = "real";

  const [chartData, setChartData] = useState({
    labels: ["Before Reforms", "After Reforms"],
    datasets: [
      {
        label: [props.benefitName],
        data: [props.preReformValue, props.postReformValue],
        backgroundColor: [preReformBarColour, postReformBarColour],
        borderRadius: 6,
      },
    ],
  });

  useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: [props.preReformValue, props.postReformValue],
        },
      ],
    }));
  }, [props.preReformValue, props.postReformValue]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          font: {
            size: normalFontSize,
          },
          color: fontColor,
        },
        onClick: false,
      },
      title: {
        display: true,
        text:
          "Estimated " + props.benefitName + " at age " + props.retirementAge,
        font: {
          weight: "bold",
          size: titleFontSize,
        },
        padding: {
          bottom: 2,
        },
        color: fontColor,
      },
      subtitle: {
        display: true,
        text: "Values are in " + moneyTerms + " terms",
        font: {
          size: normalFontSize,
        },
        padding: {
          bottom: 16,
        },
        color: fontColor,
      },
      tooltip: {
        interaction: {
          intersect: false,
        },
        backgroundColor: "white",
        borderColor: "black",
        borderWidth: 1,
        titleColor: fontColor,
        titleFont: {
          size: normalFontSize,
        },
        bodyColor: fontColor,
        bodyFont: {
          size: normalFontSize,
        },
        yAlign: "center",
        callbacks: {
          title: function (context) {
            return context[0].label.replaceAll(",", " ");
          },
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += currencyFormatter(context.parsed.y);
            }
            return label;
          },
        },
      },
      datalabels: {
        anchor: "end",
        align: "top",
        offset: -3,
        formatter: (value, context) => {
          const datasetArray = [];
          context.chart.data.datasets.forEach((dataset) => {
            if (dataset.data[context.dataIndex] !== undefined) {
              datasetArray.push(dataset.data[context.dataIndex]);
            }
          });
          let sum = datasetArray.reduce(
            (total, datapoint) => total + datapoint,
            0
          );
          if (context.datasetIndex === datasetArray.length - 1) {
            return props.benefitName + ": " + currencyFormatter(sum);
          } else {
            return "";
          }
        },
        font: {
          size: normalFontSize,
        },
        color: fontColor,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          font: {
            size: normalFontSize * 1.1,
          },
          color: fontColor,
        },
        afterFit: function (scaleInstance) {
          scaleInstance.width = 80;
        },
        stacked: false,
        grid: { display: false },
        ticks: {
          callback: function (value) {
            return currencyFormatter(value);
          },
          font: {
            size: normalFontSize,
          },
          color: fontColor,
        },
      },
      x: {
        stacked: false,
        grid: { display: false },
        ticks: {
          font: {
            size: normalFontSize,
            // weight: "bold",
          },
          color: fontColor,
        },
      },
    },
  };
  return (
    <Bar options={chartOptions} data={chartData} plugins={[ChartDataLabels]} />
  );
}

export default BarChart;

import React, { Fragment } from "react";
import {
  currencyFormatter,
  sumValues,
} from "../../shared/utilities/Formatters";
import classes from "./ComparisonTable.module.css";

const ComparisonTable = (props) => {
  return (
    <div className={classes.container}>
      <h2>Your Comparison Table</h2>
      <p>
        You can use this table to compare the expected benefits in the scenarios
        outlined above. The figures below are in real terms.
      </p>
      <table className={classes.comp_table}>
        <thead>
          <tr>
            <th rowspan="2">Retirement Age</th>
            <th colspan="2">Before Reforms</th>
            <th colspan="2">After Reforms</th>
          </tr>
          <tr>
            <th>Total Annual Pension</th>
            <th>Total Lump Sum</th>
            <th>Total Annual Pension</th>
            <th>Total Lump Sum</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((val, key) => {
            return (
              <tr key={key}>
                <td>{val.retirement_age}</td>
                <td>{currencyFormatter(val.PRE_REFORM_PENSION)}</td>
                <td>{currencyFormatter(val.PRE_REFORM_LUMP_SUM)}</td>
                <td>{currencyFormatter(val.POST_REFORM_PENSION)}</td>
                <td>{currencyFormatter(val.POST_REFORM_LUMP_SUM)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonTable;

import React, { Fragment, useState } from "react";
import InfoModal from "../../general/InfoModal";

import classes from "./ControlSwitches.module.css";

const RetirementAgeSwtich = (props) => {
  const [showModal, setShowModal] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const infoMessage = (
    <Fragment>
      <h2>Explore Your Retirement Benefits at Different Ages</h2>
      <p>
        Use the buttons below to see an illustration of your potential annual
        pension and lump sum amounts if you choose to retire at different ages.
        The buttons represent ages 60, 63, 65, and your State Pension Age (SPA),
        which is the age you're eligible for your state pension.
      </p>
      <p>Here’s what each button means for your pension estimate:</p>

      <p>
        <b>Age 60, 63, 65:</b> Selecting one of these assumes you retire at the
        selected age. It's important to note that taking your pension before
        your normal pension age in most schemes an early retirement factor is
        applied to your benefits. This reduction is calculated using specific
        factors that vary depending on the pension scheme you're in. This is
        known as an actuarial reduction and it's done to adjust for the longer
        period you'll be receiving the pension.
      </p>

      <p>
        <b>State Pension Age (SPA):</b> This is the default selection and shows
        you what you would receive if you retire at your SPA, with no reduction
        applied in any scheme.
      </p>
    </Fragment>
  );

  const highestRetirementAge = Math.max(
    ...props.data.map((item) => item.retirement_age)
  );

  return (
    <div className={classes.main_container}>
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
      )}
      <header className={classes.header}>
        <h3>Retirement Age</h3>
        <button
          id="RetirementAgeSwitchHelp"
          className={classes.help_button}
          onClick={modalHandler}
        >
          Help
        </button>
      </header>
      <p>
        Use the buttons below to see an illustration of your potential annual
        pension and lump sum amounts if you choose to retire at different ages.
        The buttons represent ages 60, 63, 65, and your State Pension Age (SPA),
        which is the age you're eligible for your state pension.
      </p>
      <div className={classes.button_container}>
        {props.data.map((item, index) => {
          const isHighest = item.retirement_age === highestRetirementAge;
          const isSelected = item.retirement_age === props.selectedAge;
          const buttonLabel = `Retire at age ${item.retirement_age}${
            isHighest ? " (SPA)" : ""
          }`;
          const buttonClasses = isSelected
            ? `${classes.button_default} ${classes.button_selected}`
            : classes.button_default;

          return (
            <button
              key={index}
              onClick={() => props.handleAgeSelect(item.retirement_age)}
              className={buttonClasses}
            >
              {buttonLabel}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default RetirementAgeSwtich;

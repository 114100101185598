import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import params from "../../shared/utilities/global_parameters";
import classes from "./TermsModal.module.css";

const Backdrop = () => {
  return <div className={classes.background} />;
};

const ModalOverlay = (props) => {
  const [tcsAgreed, setTcsAgreed] = useState(false);

  const tcsCheckHandler = () => {
    setTcsAgreed(!tcsAgreed);
  };

  return (
    <div className={classes.modal}>
      <h2>
        Regulator of Social Housing Pension Reform Illustrator - Please Read!
      </h2>
      <p>
        Welcome to the Pension Reform Illustrator. Please read these terms of
        service before using the {params.app_name_short}. It is for guidance and
        illustration purposes only.{" "}
        <b>The figures shown are not a final retirement benefit offer </b>
        and are designed to illustrate your basic service benefits only. The{" "}
        {params.app_name_short} is not intended to provide you with financial
        advice. If you require financial advice, you should contact an
        independent financial adviser.
      </p>
      <p>The assumptions we have used in this {params.app_name_short} are:</p>
      <table>
        <tbody>
          <tr>
            <td>CPI</td>
            <td>&ensp;&ensp;&ensp;&ensp;&ensp;2% pa</td>
          </tr>
          <tr>
            <td>Nominal salary increases</td>
            <td>&ensp;&ensp;&ensp;&ensp;&ensp;2% pa</td>
          </tr>
        </tbody>
      </table>
      <p>
        <b>
          We do not accept any liability for actions taken by members with
          regards to these illustrations.
        </b>
      </p>
      <label className={classes.checkbox_label}>
        <input
          className={classes.checkbox}
          type="checkbox"
          onClick={tcsCheckHandler}
        />
        I acknowledge that I have read, agree and understand the terms of
        service
      </label>
      <button
        className={`${classes.btn_main} ${
          tcsAgreed ? classes.btn_agreed : classes.btn_reject
        }`}
        onClick={tcsAgreed ? props.onClick : undefined}
      >
        Continue to the Pension Reform Illustrator
      </button>
    </div>
  );
};

const TermsModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay onClick={props.onClick} />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default TermsModal;

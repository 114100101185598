import React from "react";
import TextBlock from "../components/general/text/TextBlock";
import Footer from "../components/home/Footer";

import {
  IntroText,
  SmallPrintTextAbbreviated,
} from "../assets/data/TextBlocks";

function HomePage() {
  return (
    <div id="Home_Page">
      <TextBlock blocks={IntroText} />
      <br />
      <TextBlock blocks={SmallPrintTextAbbreviated} />
      <Footer />
    </div>
  );
}

export default HomePage;

import React from "react";
import NavBar from "./NavBar";
import RSH_logo_white from "../../assets/images/RSH_logo_white.PNG";
import classes from "./Header.module.css";

const Header = () => {
  return (
    <header className={classes.headerContainer}>
      <div className={classes.imgContainer}>
        <a
          href="https://www.gov.uk/government/organisations/regulator-of-social-housing"
          target="_blank"
        >
          <img
            src={RSH_logo_white}
            className={classes.img}
            alt="Regulator of Social Housing Logo"
          />
        </a>
      </div>
      <div className={classes.mainHeader}>
        <h1 className={classes.pageTitle}>Pension Reform Illustrator</h1>
        <NavBar />
      </div>
      <div className={classes.imgContainer}></div>
    </header>
  );
};

export default Header;

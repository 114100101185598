import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import params from "../../shared/utilities/global_parameters";
import "./TextBlocks.css";

export const IntroText = [
  {
    id: "IT",
    text: (
      <Fragment>
        <h2>
          Welcome to the Regulator of Social Housing Pension Reform Illustrator
        </h2>
        <p>
          The Pension Reform Illustrator is for pension scheme members impacted
          by the upcoming RSH pension scheme reforms.
        </p>
        <p>
          This illustrator is designed to give members an overview of how the
          reforms will impact their pension benefits. The calculator will
          provide a basic illustration of your projected benefits after the
          pension reform, as well as an illustration of the benefits that you
          would have received if the reforms had not taken place. It allows you
          to make an approximate comparison based on your inputs and certain
          assumptions about what happens in the future. It does not allow for
          all circumstances, and nor does it take account of your overall
          financial position or other pensions and savings arrangements which
          you may have.
        </p>
        <p>
          You can find information about your current HCAPS pension, including
          how to access the scheme portal OneView on{" "}
          <a
            href="http://reg-net/your-employment/payroll-pensions/pensions/"
            target="_blank"
          >
            RSHnet
          </a>
          .
        </p>
        <p>
          RSH cannot offer financial advice. For that, please{" "}
          <a href="https://www.unbiased.co.uk/" target="_blank">
            contact an independent financial adviser
          </a>
          .
        </p>
        <p>
          Visit the{" "}
          <a href="https://hcapsproposalrsh.co.uk/" target="_blank">
            consultation website
          </a>{" "}
          for more information about the proposed pension changes. Questions
          about the proposed changes and any feedback should be sent to{" "}
          <a href="mailto:hcapsproposal@rsh.gov.uk" target="_blank">
            hcapsproposal@rsh.gov.uk
          </a>
          .
        </p>
      </Fragment>
    ),
  },
];

export const SmallPrintTextAbbreviated = [
  {
    id: "SP",
    text: (
      <Fragment>
        <h2>The Small Print</h2>
        <p>
          This {params.app_name_short} is for guidance and illustration purposes
          only. It is not intended to provide you with financial advice. If you
          require financial advice, consider using an independent financial
          adviser.
        </p>
        <p>
          By using this {params.app_name_short}, you agree that you have read
          and understood the Terms & Conditions as set out in the{" "}
          <Link to="/T&Cs">T&Cs tab</Link>. The Terms & Conditions will provide
          you with more information about the limitations of this calculator and
          the assumptions we are making in the calculations.
        </p>
      </Fragment>
    ),
  },
];

export const TCTextRSH = [
  {
    id: "T&Cs",
    text: (
      <Fragment>
        <h1>Terms and Conditions</h1>
        <p>
          By using the {params.app_name_short}, you agree you have read and
          understood the Terms & Conditions set out below.
        </p>
        <h2>1. Use of this {params.app_name_short}</h2>
        <p>
          The {params.app_name_short} is solely for guidance purposes and
          provides estimates only. It is not intended to provide financial
          advice. If you require financial advice, you should contact an
          independent financial adviser. None of the Government Actuary’s
          Department (GAD), your employer or your Pension Scheme and its
          administrators have or accept any liability for financial decisions
          taken based on the information provided in the {params.app_name_short}
          .
        </p>
        <p>
          The results shown are an indication of the benefits you may receive;
          they are not guaranteed and you should not enter into financial
          commitments on the basis of the information provided.
        </p>
        <p>
          The {params.app_name_short} does not override the scheme rules or
          pension legislation.
        </p>
        <p>
          The {params.app_name_short} will not check your information against
          other systems. If you enter inaccurate information, the outputs will
          be of limited value.
        </p>
        <p>
          No data you enter into this {params.app_name_short} is stored or
          otherwise processed.
        </p>
        <h2>2. How your pension and lump sum have been estimated</h2>
        <h3>2.1. Comparison of pre and post reform of benefits</h3>
        <p>
          The results section shows a comparison of pension and lump sum
          benefits that might be received both pre and post reform of benefits.
          Member contributions have not been included in the illustration.
          Members will be shown four age retirement scenarios: age 60, 63, 65
          and SPA.
        </p>
        <h3>2.2. Assumptions</h3>
        <p>
          The results which show pension and lump sum estimates are based on the
          following key assumptions:
        </p>
        <ul>
          <li>
            Your total reformed pension is adjusted in line with prices (CPI)
            every year that you are an active member of the scheme
          </li>
          <li>
            Salaries are assumed to increase at the rate of CPI per annum. The
            salary slider lets you change this within a range of -3% to +3%.
            This is only an assumption for illustration purposes and does not
            mean that salaries will be increased at such rate in practice.
          </li>
          <li>CPI is assumed to be 2%.</li>
        </ul>
        <h3>2.3. Cash lump sum</h3>
        <p>
          At retirement, you will get an automatic lump sum in respect of your
          pre-reform benefits. This is three times your pre-reform pension. You
          are eligible to commute pension (exchange pension for a one-off lump
          sum) from both your pre and post-reform benefits, subject to certain
          terms and conditions. This calculator uses the commutation factors
          (the exchange rate of pension to lump sum) in effect at time of
          development. However, commutation factors for the pre-reform benefits
          are reviewed periodically and the amount of cash lump sum available at
          your actual retirement will depend on the commutation factors in
          effect at that time. The commutation factors for your post-reform
          benefits are fixed. Further details on the commutation options
          modelled can be found via the ‘Help’ button in the lump sum section of
          the results page.
        </p>
        <h2>3. Taxation</h2>
        <p>
          The illustrations do not allow for taxation. Your benefits will be
          subject to the various tax rates and limits in force.
        </p>
        <h2>4. Other</h2>
        <p>
          The results do not include any money purchase Additional Voluntary
          Contributions (AVCs) benefits which you may have purchased, pension
          debits or other special arrangements within the schemes.
        </p>
        <p>
          The {params.app_name_short} does not show pension from other sources,
          for example the state pension or other private arrangements you may
          have.
        </p>
        <p>
          The {params.app_name_short} only considers retirement from active
          service. There is no allowance for other exit events e.g. retirement
          from deferred, ill-health retirement.
        </p>
        <p>
          The model makes no allowance for pension debits on divorce or prior
          scheme pays tax debits.
        </p>
        <p>
          Your scheme provides survivor benefits payable in the event of your
          death, which are not shown here. See your scheme guide for details.
          Your benefit statement may also provide further information.
        </p>
        <p>
          No allowance has been made for guaranteed minimum pension (GMP) and
          any impact that GMP has on when benefits can be taken.
        </p>
        <p>
          No allowance is made for abatement, age addition, partial retirement,
          enhanced/effective pension age, transfers in, member contribution
          differentials or non-continuous service.
        </p>
      </Fragment>
    ),
  },
];

import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import StatementImage from "../../assets/images/benefits_statement_marked_up.png";
import classes from "./StatementModal.module.css";

const Backdrop = (props) => {
  return <div className={classes.background} onClick={props.onClick} />;
};

const ModalOverlay = (props) => {
  return (
    <div className={classes.modal}>
      <h2>Using your Annual Benefits Statement</h2>
      <p>
        For this page, you need to get some of the inputs from your Annual
        Benefits Statement (ABS) as at 31st March 2023. The information you need
        is highlighted below:
      </p>
      <img src={StatementImage} />
      <br />
      <button className={classes.btn_main} onClick={props.onClick}>
        Close this window
      </button>
    </div>
  );
};

const Modal = (props) => {
  useEffect(() => {
    const close = (event) => {
      if (event.keyCode === 27) {
        props.modalHandler();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          onClick={props.modalHandler}
          infoMessage={props.infoMessage}
        />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop onClick={props.modalHandler} />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default Modal;

export const currencyFormatter = (value) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
    roundingMode: "halfExpand",
  }).format(Math.round(value / 10) * 10);
};

export const capitaliseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b);

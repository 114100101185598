import React, { useContext, useEffect, useState, useMemo } from "react";
import BarChart from "../components/results/charts/BarChart";
import SalarySlider from "../components/results/switches/SalarySlider";
import RetirementAgeSwtich from "../components/results/switches/RetirementAgeSwitch";
import ComparisonTable from "../components/results/ComparisonTable";
import classes from "./3-Results.module.css";
import { OutputDataContext } from "../App";
import { submitInputData } from "../shared/apis/mainAPI";
import { InputDataContext } from "../shared/contexts/InputDataContext";
import CommutationSlider from "../components/results/switches/CommutationSlider";

const ResultsPage = () => {
  const { inputs, setInputs } = useContext(InputDataContext);
  const { outputData, setOutputData } = useContext(OutputDataContext);
  const [mouseDown, setMouseDown] = useState(false);
  const [commutation_percentage, set_commutation_percentage] = useState(
    inputs.commutation_percentage
  );
  const [salaryIncrease, setSalaryIncrease] = useState(inputs.salaryIncrease);

  const highestRetirementAge = Math.max(
    ...outputData.map((item) => item.retirement_age)
  );

  const [selectedAge, setSelectedAge] = useState(highestRetirementAge);

  const handleAgeSelect = (age) => {
    setSelectedAge(age);
  };

  let filteredData;
  filteredData = outputData.filter(
    (item) => item.retirement_age === selectedAge
  );

  let retirementData;
  retirementData = filteredData[0];

  useEffect(() => {
    if (!mouseDown) {
      setInputs({
        ...inputs,
        salaryIncrease: Number(salaryIncrease),
        commutation_percentage: Number(commutation_percentage),
      });
    }
  }, [mouseDown]);

  useEffect(() => {
    submitInputData(inputs, setOutputData);
  }, [inputs]);

  return (
    <div id="Results_Page">
      <h2>Your Illustrated Results</h2>
      <p>
        The following page provides a simple illustration of the annual pension
        and lump sum you could receive at various retirement ages up to your
        State Pension Age, both before and after the pension reforms. Results
        shown below assume members are retiring from active service i.e. members
        are assumed to start taking their pension benefits at the same time as
        leaving employment. All values provided below are in real (i.e. today's)
        money terms.
      </p>
      <RetirementAgeSwtich
        data={outputData}
        handleAgeSelect={handleAgeSelect}
        selectedAge={selectedAge}
      />
      <div className={classes.chart_container}>
        <div className={classes.chart}>
          <BarChart
            key={2}
            retirementAge={retirementData.retirement_age}
            preReformValue={retirementData.PRE_REFORM_PENSION}
            postReformValue={retirementData.POST_REFORM_PENSION}
            benefitName="Pension"
          />
        </div>
        <div className={classes.chart}>
          <BarChart
            key={2}
            retirementAge={retirementData.retirement_age}
            preReformValue={retirementData.PRE_REFORM_LUMP_SUM}
            postReformValue={retirementData.POST_REFORM_LUMP_SUM}
            benefitName="Lump Sum"
          />
        </div>
      </div>
      <div className={classes.switch_container}>
        <SalarySlider
          salaryIncrease={salaryIncrease}
          setSalaryIncrease={setSalaryIncrease}
          setMouseDown={setMouseDown}
        />
        <CommutationSlider
          commutation_percentage={commutation_percentage}
          set_commutation_percentage={set_commutation_percentage}
          setMouseDown={setMouseDown}
        />
      </div>
      <ComparisonTable data={outputData} />
    </div>
  );
};

export default ResultsPage;

import React, { Fragment, useState, useEffect, useContext } from "react";
import Input from "../components/yourdetails/inputs/Input";
import { InputDataContext } from "../shared/contexts/InputDataContext";
import StatementModal from "../components/yourdetails/StatementModal";
import PartTimeServiceInput from "../components/yourdetails/inputs/PartTimeServiceInput";

import CalculateButton from "../components/yourdetails/CalculateButton";
import classes from "./2-YourDetails.module.css";

function YourDetailsPage() {
  const { inputs, setInputs, valid, setValid } = useContext(InputDataContext);

  let targetDate = new Date("2024-04-01");
  const max_dob = targetDate.setFullYear(targetDate.getFullYear() - 16);
  const min_dob = new Date("1958-04-01");

  function calculateHighestService(dob) {
    let date_dob = new Date(dob);
    let this_today = new Date();
    const max_age = this_today - date_dob;
    const service = max_age / 31556952000 - 16;
    return service;
  }

  const [showModal, setShowModal] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  return (
    <div id="Your_Details_Page">
      <h2>Your Pension History</h2>
      {showModal && <StatementModal modalHandler={modalHandler} />}
      <p>
        Please answer the following questions to allow us to provide you with an
        estimate of your pension benefits. You can find everything you need to
        answer the following questions in your most recent Annual Benefits
        Statement (ABS). For help finding the information we need, please click
        the button below.
      </p>
      <button className={classes.help_button} onClick={modalHandler}>
        Where can I find this information?
      </button>
      <Input
        input={"schemeName"}
        inputType={"select"}
        options={[{ value: "Final Salary Tier" }, { value: "HCAPS CARE Tier" }]}
        text={"Which pension scheme are you currently a member of?"}
      />
      {valid.schemeName && (
        <Input
          input={"dob"}
          inputType={"date"}
          text={`What is your date of birth?`}
          maxValue={max_dob}
          minValue={min_dob}
          minValErrorMsg={
            "You cannot use this modeller if you have already passed your State Pension Age (SPA) on the date of the reform."
          }
          maxValErrorMsg={
            "You can not be under the age of 16 on the reform date."
          }
          help={null}
        />
      )}
      {valid.schemeName && valid.dob ? (
        <Input
          input={"salaryCurrent"}
          inputType={"integer"}
          text={"What are your current pensionable earnings?"}
          help={
            <Fragment>
              <h2>Current Pensionable Earnings</h2>
              <p>
                This should be pensionable earnings and should be the Full-Time
                Equivalent amount. For example, if you work three days per week
                and earn £15,000 pa for those three days, then your FTE would be
                what you would earn if you worked five days, in this case
                £25,000. Please note that the final pensionable pay used in this
                illustrator is based on gross pay at the point of retirement,
                which differs from the actual final pensionable pay definitions
                that will be used at retirement.
              </p>
            </Fragment>
          }
          minValue={0}
          maxValue={250000}
          minValErrorMsg={
            "The current pensionable earnings you've entered is too high."
          }
          maxValErrorMsg={
            "The current pensionable earnings you've entered is too low."
          }
        />
      ) : null}
      {inputs.schemeName === "Final Salary Tier" &&
      valid.dob &&
      valid.salaryCurrent ? (
        <Input
          input={"reckonableService"}
          inputType={"number"}
          text={"What is your total reckonable service in years?"}
          minValue={0}
          maxValue={calculateHighestService(inputs.dob)}
          maxValErrorMsg={"The service you’ve entered is too high."}
          minValErrorMsg={"Your service cannot be below 0."}
        />
      ) : null}
      {inputs.schemeName === "HCAPS CARE Tier" &&
      valid.dob &&
      valid.salaryCurrent ? (
        <Input
          input={"accruedPension"}
          inputType={"number"}
          text={"What is your accrued CARE pension?"}
          minValue={0}
          maxValue={100000}
          maxValErrorMsg={"The accrued pension you have entered is too high."}
          minValErrorMsg={"Accrued pension cannot be below 0."}
        />
      ) : null}
      {((inputs.schemeName === "Final Salary Tier" &&
        valid.reckonableService) ||
        (inputs.schemeName === "HCAPS CARE Tier" && valid.accruedPension)) &&
      valid.dob &&
      valid.salaryCurrent ? (
        <Input
          input={"hasPT"}
          inputType={"select"}
          options={[{ value: "Yes" }, { value: "No" }]}
          text={"Do you have any current or future plans for part-time work?"}
          help={null}
        />
      ) : null}
      {((inputs.schemeName === "Final Salary Tier" &&
        valid.reckonableService) ||
        (inputs.schemeName === "HCAPS CARE Tier" && valid.accruedPension)) &&
      valid.dob &&
      valid.salaryCurrent &&
      inputs.hasPT === "Yes" ? (
        <PartTimeServiceInput />
      ) : null}
      {((inputs.schemeName === "Final Salary Tier" &&
        valid.reckonableService) ||
        (inputs.schemeName === "HCAPS CARE Tier" && valid.accruedPension)) &&
      valid.dob &&
      valid.salaryCurrent &&
      valid.hasPT ? (
        <div className={classes.button_container}>
          <CalculateButton />
        </div>
      ) : null}
    </div>
  );
}
export default YourDetailsPage;

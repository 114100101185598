import React, { Fragment, useState, useRef, useEffect } from "react";
import Header from "../components/layout/Header";
import TermsModal from "../components/layout/TermsModal";
import classes from "./0-Layout.module.css";

function Layout(props) {
  const headerRef = useRef(null);
  const [showModal, setShowModal] = useState(true);

  const closeModalHandler = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      <Header ref={headerRef} />
      <main className={classes.main_content}>{props.children}</main>
      {showModal && <TermsModal onClick={closeModalHandler} />}
    </Fragment>
  );
}

export default Layout;

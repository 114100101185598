export const submitInputData = async (inputs, setOutputData) => {
  let address =
    (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "/api/setup";

  const InputData = {
    date_of_birth: inputs.dob,
    current_pensionable_earnings: inputs.salaryCurrent,
    reckonable_service: inputs.reckonableService,
    commutation_preference: inputs.commutation_percentage,
    salary_increase_rate: inputs.salaryIncrease,
    accrued_care_pension: inputs.accruedPension,
    scheme_name_current: inputs.schemeName,
    hasPT: inputs.hasPT,
    pt_service: inputs.partTimeService,
  };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(InputData),
  };

  try {
    const response = await fetch(address, requestOptions);
    // const data = await response.json();
    let text = await response.text();
    text = text.replace(/NaN/g, "null"); // replace NaN with null
    const data = JSON.parse(text);
    setOutputData(data);
  } catch (error) {
    console.log(error);
  }
};

import React, { Fragment, useState } from "react";
import InfoModal from "../../general/InfoModal";

import classes from "./ControlSwitches.module.css";

const CommutationSlider = (props) => {
  const [showModal, setShowModal] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const sliderHandler = (event) => {
    props.setMouseDown(true);
    props.set_commutation_percentage(event.target.value);
  };

  const infoMessage = (
    <Fragment>
      <h2>Voluntary Commutation</h2>
      <p>
        Commutation percentage refers to the amount of your pension that you
        exchange into a one-off lump sum. This slider pertains to commutation
        across all pension schemes modelled by the reform illustrator. For the
        Final Salary scheme, there is an automatic lump sum of 3 times your
        annual pension, which is not affected by the slider.
      </p>
      <p>
        The maximum commutation that you can take is in line with the HMRC
        tax-free cash limit, and it will be different between different schemes.
        If you set this slider to 50%, then the tool will calculate 50% of the
        respective maximum commutation for each scheme being modelled.
      </p>
    </Fragment>
  );

  return (
    <div className={classes.main_container}>
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
      )}
      <header className={classes.header}>
        <h3>Voluntary Commutation</h3>
        <button
          id="CommutationSliderHelp"
          className={classes.help_button}
          onClick={modalHandler}
        >
          Help
        </button>
      </header>
      <p>
        This slider will set the amount of annual pension you exchange for a
        one-off cash lump sum on the date you retire. The percentage represents
        the proportion of the HMRC maximum lump sum you can receive.
      </p>
      <div className={classes.radio_container}>
        <input
          id="lumpSum"
          type="range"
          min="0"
          max="100"
          step="1"
          value={props.commutation_percentage}
          onChange={sliderHandler}
          className="slider"
          onMouseUp={() => {
            props.setMouseDown(false);
          }}
          onKeyUp={() => {
            props.setMouseDown(false);
          }}
        />
        <p>{props.commutation_percentage}%</p>
      </div>
    </div>
  );
};

export default CommutationSlider;

import React, { useContext, useEffect, useState } from "react";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
import classes from "./ServiceInputs.module.css";

const PartTimeServiceInput = () => {
  const { inputs, setInputs } = useContext(InputDataContext);
  const [partTimeService, setPartTimeService] = useState(
    inputs.partTimeService
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [percentage, setPercentage] = useState("");
  const [error, setError] = useState(null);
  const minDate = new Date("2023-04-01").toLocaleDateString("en-GB");

  const addService = () => {
    const newService = {
      From: startDate,
      To: endDate,
      Proportion: percentage,
    };

    if (startDate < minDate) {
      setError(
        "Part-time periods must start after the date of your most recent Annual Benefits Statement (ABS).  This is currently 1 April 2023."
      );
      return;
    }

    if (new Date(startDate) < new Date(minDate)) {
      setError(
        "Part-time periods must start after the date of your most recent Annual Benefits Statement (ABS). This is currently 1 April 2023."
      );
      return;
    }

    if (percentage <= 0 || percentage >= 99) {
      setError("Percentage must be between 0 and 100.");
      return;
    }

    const isOverlap = (service1, service2) => {
      const start1 = new Date(service1.From);
      const end1 = new Date(service1.To);
      const start2 = new Date(service2.From);
      const end2 = new Date(service2.To);

      return start1 <= end2 && start2 <= end1;
    };

    for (let i = 0; i < partTimeService.length; i++) {
      if (isOverlap(partTimeService[i], newService)) {
        setError("This entry overlaps with an existing part-time service.");
        return;
      }
    }

    // const isChronalogical = (service1, service2) => {
    //   const start1 = new Date(service1.From);
    //   const start2 = new Date(service2.From);

    //   return start1 >= start2;
    // };

    // for (let i = 0; i < partTimeService.length; i++) {
    //   if (isChronalogical(partTimeService[i], newService)) {
    //     setError("Please enter services in chronalogoical order.");
    //     return;
    //   }
    // }

    // setPartTimeService([
    //   ...partTimeService,
    //   { From: startDate, To: endDate, Proportion: percentage },
    // ]);

    const updatedPartTimeService = [...partTimeService, newService];

    updatedPartTimeService.sort((a, b) => new Date(a.From) - new Date(b.From));

    setPartTimeService(updatedPartTimeService);

    setStartDate("");
    setEndDate("");
    setPercentage("");
    setError(null);
  };

  const removeService = (index) => {
    setPartTimeService(partTimeService.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setInputs({ ...inputs, partTimeService: partTimeService });
  }, [partTimeService]);

  return (
    <div className={classes.service_container}>
      <h3>Tell us about your part-time working plans</h3>
      <p>
        If you do not anticipate any periods of part-time service starting from
        1 April 2023 onwards, then please leave this section blank or select
        'No' for the preceding question.
      </p>
      <p>
        Enter details of any expected future part-time work starting on or after
        1 April 2023. This can include speculative periods of part-time work but
        be aware that this will reduce your projected benefits.
      </p>
      <p>
        In the 'Percentage' field, specify a number between 0 and 100 that
        reflects the percentage of a full-time schedule you plan to work during
        these periods. For instance, if your plan is to work 28 hours per week
        when the full-time equivalent is 35 hours per week, that corresponds to
        an 80% part-time rate. Hence, you would enter '80' in the percentage
        field.
      </p>
      <p>
        Please note that any time outside of the periods you input will be
        considered full-time service by default, unless otherwise indicated.
      </p>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div className={classes.subContainer}>
        <div className={classes.inputContainer}>
          <div className={classes.label_input}>
            <label>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={classes.input}
            />
          </div>
          <div className={classes.label_input}>
            <label>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={classes.input}
            />
          </div>
          <div className={classes.label_input}>
            <label>Percentage (%):</label>
            <input
              type="number"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
              min={1}
              step={1}
              max={99}
              className={classes.input}
            />
          </div>
        </div>
        <button onClick={addService}>Add Service</button>
      </div>
      <table>
        <thead>
          <tr>
            <th className={classes.leftColumn}></th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Proportion</th>
            <th className={classes.rightColumn}></th>
          </tr>
        </thead>
        <tbody>
          {partTimeService.map((service, i) => (
            <tr key={i}>
              <td className={classes.leftColumn}>{i + 1}.</td>
              <td>{new Date(service.From).toLocaleDateString("en-GB")}</td>
              <td>{new Date(service.To).toLocaleDateString("en-GB")}</td>
              <td>{service.Proportion}%</td>
              <td className={classes.rightColumn}>
                <button onClick={() => removeService(i)}>X</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartTimeServiceInput;

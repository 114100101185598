import { createContext } from "react";

export const InputDataContext = createContext({
  inputs: {
    schemeName: null,
    dob: null,
    salaryCurrent: null,
    reckonableService: 0,
    accruedPension: 0,
    hasPT: null,
    partTimeService: [],
    salaryIncrease: 0,
    commutation_percentage: 0,
    spa: null,
    salaryIncrease: 0,
    commutation_percentage: 0,
  },
  setInputs: () => {},
  valid: {
    schemeName: false,
    dob: false,
    salaryCurrent: false,
    reckonableService: false,
    accruedPension: false,
    hasPT: false,
  },
  setValid: () => {},
});
